document.addEventListener("DOMContentLoaded", function() {

    const heroHead = document.querySelector(".hero-head");
    heroHead.querySelector(".head-think").classList.add("show");
    heroHead.querySelector(".sub-head").classList.add("show");


    const swiper = new Swiper('.hero-slider', {
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        effect: "fade",
        allowTouchMove: true,

        breakpoints: {
            768: {
                allowTouchMove: false,
            }
        }
    });

    AOS.init({
        duration: 900,
        disable: "phone",
    });

    const heroList = document.querySelector(".hero-list");
    const links = document.querySelectorAll(".hero-list a");

    links.forEach(link => {
        link.addEventListener("mouseenter", handleMouseEnter);
    });

    heroList.addEventListener("mouseleave", handleMouseLeave);

    function handleMouseEnter(event) {

        const currentLink = event.target;

        currentLink.classList.add("active");

        links.forEach(link => {
            if (link !== currentLink) {

                link.classList.remove("active");
            }
        });
    }

    function handleMouseLeave(event) {

        links.forEach(link => {

            link.classList.remove("active");
        });

        links[0].classList.add("active");
    }


    const linksScroll = document.querySelectorAll('[href]:not(.no-to-scroll)');

    linksScroll.forEach(link => {
        link.addEventListener('click', handleClick);
    });

    function handleClick(event) {
        event.preventDefault();

        const targetId = this.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            scrollToElement(targetElement);
        }
    }

    function scrollToElement(element) {
        const offsetTop = element.offsetTop;

        window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
        });
    }
});